import styled from "styled-components"

export const Page404Container = styled.div`
  font-family: "Open Sans";
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
`

export const Number = styled.div`
  font-size: 9rem;
  font-weight: 600;
  color: #ed213a;
`

export const Headline = styled.h1`
  font-family: "Open Sans";
  margin-bottom: 0.3rem;
  color: black;
`

export const Text = styled.p``
