import React from "react"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "./i18n"

import { Headline, Number, Page404Container, Text } from "./style"

const PageNotFound = () => {
  const intl = useIntl()

  return (
    <Page404Container>
      <Number>404</Number>
      <Headline>{intl.formatMessage(i18n.title)}</Headline>
      <Text>{intl.formatMessage(i18n.text)}</Text>
    </Page404Container>
  )
}

export default PageNotFound
