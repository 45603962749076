import { defineMessages } from "react-intl";

const scope = 'notFound';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '404 - Seite nicht gefunden',
  },

  text: {
    id: `${scope}.text`,
    defaultMessage: 'Es tut uns leid, aber diese Seite konnte leider nicht gefunden werden.'

  }
});