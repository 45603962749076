import React from "react";

import Page from "../components/common/page";
import PageNotFound from "../components/page-components/404";

const Page404 = () => (
  <Page title="page404">
    <PageNotFound />
  </Page>
);

export default Page404;
